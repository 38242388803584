import { withSearchParams } from 'api/utils/routes'
import { VenueDashboardTenderStatus } from 'generated/generated-graphql'
import { TabsTitles } from 'routes/Notifications/NotificationsTabsGroup'

export type ReturnToSearchParam = {
  returnTo?: string
}

type FirebaseSignInSearchParam = {
  firebaseToken: string
}

export enum SubRoutes {
  EditDraft = 'edit-draft',
  CreateTender = 'create-tender',
}

export const Route = {
  FrontPage: () => `/`,
  Subscriptions: () => `/brand-subscriptions`,
  SignIn: (searchParam?: ReturnToSearchParam) =>
    `/sign-in${withSearchParams(searchParam)}`,
  FirebaseSignIn: (searchParam?: FirebaseSignInSearchParam) =>
    `/firebase-sign-in${withSearchParams(searchParam)}`,
  SignUp: (searchParam?: ReturnToSearchParam) =>
    `/sign-up${withSearchParams(searchParam)}`,
  ForgotPassword: () => `/forgot-password`,
  BrandProfile: () => `/brand-profile`,
  VenueProfile: () => `/venue-profile`,
  ChooseYourVenue: (searchParam?: ReturnToSearchParam) =>
    `/choose-your-venue${withSearchParams(searchParam)}`,
  BidDetail: ({
    id,
    searchParams,
  }: {
    id: number
    searchParams?: { openMessageModal?: boolean }
  }) => `/bid-detail/${id}${withSearchParams(searchParams)}`,
  EditBid: ({ id }: { id: number }) => `/bid-detail/${id}/edit`,
  Connect: () => '/connect',
  CreateTender: (searchParams?: {
    tenderId?: number
    tenderDraftId?: number
  }) => `/${SubRoutes.CreateTender}${withSearchParams(searchParams)}`,
  Dashboard: (searchParams?: {
    filter?: VenueDashboardTenderStatus
    openEnterpriseInfoModal?: boolean
  }) => `/dashboard${withSearchParams(searchParams)}`,
  EditTenderDraft: ({ id }: { id: number }) =>
    `/tender-detail/${id}/${SubRoutes.EditDraft}`,
  EditTender: ({
    id,
    searchParams,
  }: {
    id: number
    searchParams?: {
      activeStepIndex: number
    }
  }) => `/tender-detail/${id}/edit${withSearchParams(searchParams)}`,
  TenderDetail: ({ id }: { id: number }) => `/tender-detail/${id}`,
  CreateBid: ({ tenderId }: { tenderId: number }) =>
    `/tender-detail/${tenderId}/create-bid`,
  BrandLandingPage: () => '/brand',
  VenueLandingPage: () => '/venue',
  CreateAutomatedBid: () => '/create-automated-bid',
  EzTendaMatch: () => '/eztenda-match',
  Match: () => '/match',
  EzTendaMatchLanding: () => '/eztenda-match-landing',
  MatchBidDetail: ({ id }: { id: number }) => `/match-bid-detail/${id}`,
  EditMatchBid: ({
    id,
    searchParams,
  }: {
    id: number
    searchParams?: {
      activeStepIndex: number
    }
  }) => `/match-bid-detail/${id}/edit${withSearchParams(searchParams)}`,
  VenueBillingData: () => '/venue-billing-data',
  BrandBillingData: () => '/brand-billing-data',
  ProductPortfolio: () => '/product-portfolio',
  VatAndAwrsNumbers: () => '/vat-awrs',
  VenueNotificationSettings: () => `/venue-notification-settings`,
  BrandNotificationSettings: () => `/brand-notification-settings`,
  TenderPreferences: () => '/tender-preferences',
  ChangeEmail: () => '/change-email',
  UserData: (searchParams?: { emailToChangeToken: string }) =>
    `/user-data${withSearchParams(searchParams)}`,
  CreateMultipleBids: () => '/create-bids',
  Notifications: (searchParams?: { tab: TabsTitles }) =>
    `/notifications${withSearchParams(searchParams)}`,
  BrandSubscriptionOverview: (searchParams?: {
    enterpriseToken?: string
    openEnterpriseInfoModal?: boolean
  }) => `/brand-subscription-overview${withSearchParams(searchParams)}`,
  BrandPlanSelection: () => `/plan-selection`,
  BrandInvoices: () => '/brand-invoices',
}
