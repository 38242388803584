export const ErrorMessage = {
  Required: 'This field is required',
  PasswordMinLength: 'Password length is not sufficient',
  PasswordsDontMatch: 'Passwords do not match',
  EmailWrongFormat: 'Wrong email format',
  EmailAlreadyExists: 'Email already exists. Please sign in.',
  AddressNotSufficient: 'Address is not sufficient',
  RequiredConsent: 'Consent is required',
  CompanyNumberMinLength: 'Company number must be 8 characters long',
  CompanyNumberNotFound: 'Company data was not found',
  VatNumberNotFound:
    'Company VAT data from the provided VAT number was not found. You probably used wrong VAT number.',
  VatNumberLength: 'VAT number should be 6 or 9 characters long',
  AwrsNumberMinLength: 'AWRS number must be 15 characters long',
  AwrsNumberNotFound: 'AWRS data was not found',
  WholesalerMinLength:
    'Please select at least 1 supported wholesaler, or select Direct Supply or Any wholesaler.',
  VenueTypeMinLength:
    'Please select at least 1 venue type, or select any venue type.',
  ListingTypeMinLength:
    'Please select at least 1 listing type, or select any listing type.',
  BespokeAuthError:
    'This bespoke package is meant for another user. Please sign in with the correct one and open the link again.',
  IncorrectTokenFormat: 'Token is in invalid format.',
  Subscription: 'Please select at least 1 subscription type.',
}
