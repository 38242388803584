import { ComponentMultiStyleConfig } from '@chakra-ui/react'

import { Colors } from 'theme/constants'

export const Progress: ComponentMultiStyleConfig = {
  parts: ['filledTrack', 'track'],

  baseStyle: {
    track: {
      borderRadius: '12px',
      borderColor: Colors.brand.wildBlueYonder,
      bg: Colors.brand.fogGray,
      borderWidth: '1px',
    },
    filledTrack: { bg: Colors.brand.ultramarine },
  },
}
