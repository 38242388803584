import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { keyBy } from 'lodash'

import { PropsWithChildrenRequired } from 'constants/typings'
import {
  Language,
  Region,
  Translation as TranslationObjectType,
  useTranslationsQuery,
} from 'generated/generated-graphql'

import { useAuth } from './UserProvider'

const RegionLanguageMapper: Record<Region, Language> = {
  [Region.UnitedKingdom]: Language.En,
  [Region.Australia]: Language.En,
  [Region.Spain]: Language.Es,
}

interface TranslationsProviderContextType {
  translations: Record<string, TranslationObjectType> | undefined
  getTranslation: (componentId: string, isPlural?: boolean) => string
  region: Region
  changeRegion: (value: Region) => void
}

const TranslationsContext = createContext({} as TranslationsProviderContextType)

export const useTranslations = (): TranslationsProviderContextType =>
  useContext(TranslationsContext)

const TranslationsProvider = ({ children }: PropsWithChildrenRequired) => {
  const { userData } = useAuth()
  const [region, setRegion] = useState(Region.UnitedKingdom)

  useEffect(() => {
    if (userData?.region) {
      setRegion(userData.region)
    }
  }, [userData?.region])

  const { data: translationsData } = useTranslationsQuery()

  const translations = keyBy(
    translationsData?.translations?.filter(
      ({ language }) => language === RegionLanguageMapper[region]
    ),
    'componentId'
  )
  // TODO maybe call the function as t so it is shorter as we will use it all across app
  const getTranslation = useCallback(
    (componentId: string, isPlural?: boolean) => {
      if (translations && isPlural) {
        return translations[componentId]?.pluralValue ?? componentId
      }

      return (translations && translations[componentId]?.value) ?? componentId
    },
    [translations]
  )

  // TODO use this to change region on landing page and pages where user is yet not authorized
  const changeRegion = useCallback(
    // eslint-disable-next-line consistent-return
    (selectedRegion: Region) => {
      setRegion(selectedRegion)
    },
    []
  )

  const contextValue = useMemo(
    () => ({
      getTranslation,
      translations,
      region,
      changeRegion,
    }),
    [getTranslation, translations, region, changeRegion]
  )

  return (
    <TranslationsContext.Provider value={contextValue}>
      {children}
    </TranslationsContext.Provider>
  )
}

export default TranslationsProvider
