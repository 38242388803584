import { Button, Heading, VStack } from '@chakra-ui/react'

interface ErrorFallbackProps {
  resetError: () => void
}

export const ErrorFallback = ({ resetError }: ErrorFallbackProps) => (
  <VStack
    alignItems="center"
    justifyContent="center"
    spacing="32px"
    height="100%"
    flex="1 1 100%"
  >
    <Heading fontSize="xl">Something went wrong</Heading>
    <Button variant="outline" onClick={() => resetError()}>
      Try Again
    </Button>
  </VStack>
)
